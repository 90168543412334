import MicroModal from "micromodal";

class ComplaintRequest {
    constructor(selector) {
        this.requestForms = document.querySelectorAll(selector);
        return this.onSubmitHandler();
    }
    onSubmitHandler() {
        this.requestForms.forEach(form => {
            form.addEventListener('submit', this.sendRequest);
        })
    }
    sendRequest(event) {
        event.preventDefault();
        MicroModal.show('modal-complaint');
        event.target.reset();
    }
}
export default ComplaintRequest;