import sass from '../scss/main.scss';
import Accordion from 'accordion-js';
import Swiper from 'swiper';
import Tabs from 'a11y-accordion-tabs';
import './search-mobile.js';
import './categories-menu.js';
import './sliders';
import './input-file';
import './map.js';
import Choices from 'choices.js'
import Inputmask from "inputmask";
import MicroModal from 'micromodal';
import FormRequest from './form-request.js';
import ComplaintRequest from './complaint';
new ComplaintRequest('.complaint-form');

new FormRequest('.request-form');

MicroModal.init({
    openTrigger: 'data-custom-open',
    disableScroll: true,
    disableFocus: false,
    awaitCloseAnimation: true,
});

if(document.querySelector('.js-choice') !== null){
    new Choices('.js-choice:not(.worktime)', {
        itemSelectText: '',
        loadingText: 'Загрузка...',
        noResultsText: 'Ничего не найдено',
        shouldSort: false,
    });
    new Choices('.js-choice.worktime', {
        itemSelectText: '',
        loadingText: 'Загрузка...',
        searchEnabled: false,
        shouldSort: false,
    });
}

let inputsTel = document.querySelectorAll('input[type="tel"]');
inputsTel.forEach(el => {
    Inputmask({
        "mask": "+7 (999) 999 99 99",
        "clearIncomplete": true,
        "placeholder": ""
    }).mask(el);
});

new Accordion('.accordion-faq-js', {
    duration: 500,
    showItem: false,
    onToggle: function(currentElement, allElements) {
      console.log(currentElement);
    }
});

var mySwiper = new Swiper ('.swiper-reviews-js', {
    // Optional parameters
    direction: 'horizontal',
    loop: true,
    autoHeight: true,

    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next-js',
      prevEl: '.swiper-button-prev-js',
    },
});

var tabs = document.getElementById("js-tabs");
new AccordionTabs(tabs, {
    breakpoint: 800,
    tabsAllowed: true,
    selectedTab: 1,
    startCollapsed: false
});

var moreServices = document.querySelector(".more-services-js");
moreServices.addEventListener("click", function () {
    var servicesGridCols = document.querySelectorAll(".services-grid-js .col");
    Array.prototype.forEach.call(servicesGridCols, function(el, i){
        if (i > 7)
            el.classList.toggle("active");
        });
    this.classList.toggle("active");
});

try {
    const anchors = document.querySelectorAll('.menu-main-js a[href*="#"]')

    for (let anchor of anchors) {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();
            const blockID = anchor.getAttribute('href').substr(1);
            document.getElementById(blockID).scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        });
    }
} catch (e) {
    console.log(e.message)
}

try {
    var overlayMenu = document.querySelector(".overlay-menu-js");
    var hamburger = document.querySelector(".hamburger-js");
    var menuSidebar = document.querySelector(".menu-sidebar-wrap-js");
    var menuSidebarClose = document.querySelector(".menu-sidebar-close-js");

    hamburger.addEventListener('click', function (e) {
        menuSidebar.classList.toggle("active");
        this.classList.toggle("active");
        overlayMenu.classList.add("active");
    });
    overlayMenu.addEventListener('click', function (e) {
        menuSidebar.classList.remove("active");
        overlayMenu.classList.remove("active");
    });
    menuSidebarClose.addEventListener('click', function (e) {
        menuSidebar.classList.remove("active");
        overlayMenu.classList.remove("active");
    });

} catch (e) {
    console.log(e.message)
}

// Открывает модальные окна через url.
let hash = window.location.hash;
if (hash != '') {
    let element = document.querySelector(hash);
    if (!!element) {
        MicroModal.show(hash.substring(1));
    }
}
